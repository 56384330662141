import PropTypes from 'prop-types';
import { useRef, useState } from 'react';
import parse from 'html-react-parser';

import { useAnimationUrls } from '@components/Animation';
import { localizedString } from '@languages';
import { useFlowTypeContext } from '@js/lib/components/v2/App/FlowTypeProvider';
import Page from '@lib/components/v2/Page';
import { SimplePageContent } from '@lib/components/v2/SimplePageContent';
import APIs from '@services/APIs';
import { Error500 } from '@lib/components/v2/errors';

export const CaptureSideOfCard = ({ onBack, onNext }) => {
  const [isUploading, setIsUploading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const { flowType } = useFlowTypeContext();
  const inputRef = useRef();
  const { animationUrls } = useAnimationUrls();

  const uploadImage = (imagefile) => {
    const params = { frontFile: imagefile, flowType, ocr: false, idType: 'Additional Documents' };

    setIsUploading(true);

    APIs.uploadImage(params, {})
      .then(({ status, msg: error }) => {
        if (status === 'error') {
          console.warn(error);
          throw error;
        }
        setIsUploading(false);
        onNext();
      })
      .catch(() => {
        setIsUploading(false);
        setHasError(true);
      });
  };

  const captureSideCardImage = (e) => {
    const imagefile = e?.target?.files?.[0];
    if (!imagefile) {
      return;
    }
    uploadImage(imagefile);
  };

  const footerButtons = isUploading
    ? [
        {
          label: '',
          variant: 'transparent',
          dataTestId: 'cptrId-back',
          'aria-hidden': true
        },
        {
          label: localizedString('uploading'),
          variant: 'transparent',
          loading: true,
          dataTestId: 'id-Uploading'
        }
      ]
    : [
        {
          label: localizedString('back'),
          variant: 'transparent',
          onClick: () => onBack(),
          dataTestId: 'cptrId-back'
        },
        {
          label: localizedString('captureMyID'),
          type: 'submit',
          onClick: () => inputRef.current.click(),
          dataTestId: 'cptrId-btn'
        }
      ];

  if (hasError) {
    return (
      <Error500
        buttons={[
          {
            label: localizedString('pleaseTryAgain'),
            type: 'submit',
            onClick: () => setHasError(false),
            dataTestId: 'try-again-btn'
          }
        ]}
      />
    );
  }

  return (
    <>
      <input
        type="file"
        name="image"
        accept="image/*"
        capture="environment"
        onChange={captureSideCardImage}
        ref={inputRef}
        style={{ opacity: 0, zIindex: 99 }}
        data-testid="capture-side-card-upload-doc-hidden-input"
        aria-hidden="true"
      />
      <Page buttons={footerButtons} forceFillViewPort>
        <SimplePageContent
          title={parse(localizedString('app.captureSideCard.title'))}
          description={parse(localizedString('app.captureSideCard.description'))}
          animationUrl={animationUrls.SIDE_OF_CARD}
        />
      </Page>
    </>
  );
};

CaptureSideOfCard.propTypes = {
  onBack: PropTypes.func.isRequired,
  onNext: PropTypes.func.isRequired
};
