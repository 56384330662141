import React, { useState, useEffect } from 'react';
import unionBy from 'lodash/unionBy';
import isEmpty from 'lodash/isEmpty';
import pipeFp from 'lodash/fp/pipe';
import mapFp from 'lodash/fp/map';
import intersectionWithFp from 'lodash/fp/intersectionWith';
import orderByFp from 'lodash/fp/orderBy';
import sortByFp from 'lodash/fp/sortBy';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { localizedString } from '@languages';
import APIs from '@services/APIs';
import parse from 'html-react-parser';
import VoiFlowV2Action from '@store/actions/voiFlowV2';
import { isShownPrivacy } from '@lib/Utils';
import { DocumentListScreen } from '@lib/pages/v2/DocumentListScreen';

import {
  FLOW_V2_HIDE_IMAGES_IN_DOC_LIST,
  VOI_FLOW_V2_FILTER_DOCS,
  VOI_FLOW_V2_ONLY_INCLUDE_IN_THIRD_LIST
} from '@spotMobileConfig';

import { useVoiV2DocListPart1, useVoiV2DocListPart2 } from './documentList';
import { getDocumentList } from './getDocumentList';

const nthLabel = {
  0: 'first',
  1: 'second',
  2: 'third',
  3: 'fourth',
  4: 'fifth',
  5: 'sixth',
  6: 'seventh',
  7: 'eighth',
  8: 'ninth',
  9: 'tenth'
};

function DocumentSelection({
  initialSelectedDocumentList = [],
  requiredDocumentConfig,
  enableOneDocCondition,
  onGoBack,
  onNextStep,
  currentDocument,
  clearCardDetails
}) {
  const [wellDone, setWellDone] = useState(false);
  const documentListPart1 = useVoiV2DocListPart1(currentDocument);
  const documentListPart2 = useVoiV2DocListPart2();

  const docTypeNamesListPart1 = documentListPart1.map((doc) => doc.type);
  const docTypeNamesListPart2 = documentListPart2.map((doc) => doc.type);

  const docTypeAlreadyUploaded = initialSelectedDocumentList
    .filter((doc) => doc.captured)
    .map((doc) => doc.type);
  const availableDocuments = unionBy(
    initialSelectedDocumentList,
    [...documentListPart1, ...documentListPart2],
    'type'
  );

  const [selectedDocTypes, setSelectedDocTypes] = useState(
    initialSelectedDocumentList.map((doc) => doc.type)
  );

  const [currentDocumentList, setCurrentDocumentList] = useState(() => getCurrentDocumentList());

  useEffect(() => {
    if (isEmpty(initialSelectedDocumentList)) {
      clearCardDetails();
      APIs.status('selectId', { resetTables: enableOneDocCondition ? 'no' : 'yes' });
    }
  }, []);

  useEffect(() => {
    setCurrentDocumentList(getCurrentDocumentList());
  }, [selectedDocTypes]);

  useEffect(() => {
    if (wellDone && enableOneDocCondition) {
      onNextStep([currentDocumentList[0]]);
    }
  }, [wellDone]);

  const { backurl } = document.body.dataset;
  const footerButtons = [];
  if (backurl || isShownPrivacy('VOI_FLOW_V2')) {
    footerButtons.push({
      label: localizedString('back'),
      variant: 'transparent',
      onClick: handleGoBack,
      dataTestId: 'docs-back'
    });
  }
  footerButtons.push({
    label: localizedString('continue'),
    type: 'submit',
    disabled: !wellDone,
    onClick: handleNextStep,
    dataTestId: 'docs-continue'
  });

  const selectedDocumentCount = currentDocumentList.filter((doc) => doc.checked).length;
  const progress = wellDone ? 100 : (selectedDocumentCount * 100) / 3;

  let title = '';
  let description = '';

  if (wellDone) {
    title = localizedString(
      'documentSelection.voiv2.afterSelect.title',
      nthLabel[selectedDocumentCount]
    );
    description = parse(localizedString('documentSelection.voiv2.afterSelect.description'));
  } else {
    title = localizedString(
      'documentSelection.voiv2.beforeSelect.title',
      nthLabel[selectedDocumentCount]
    );
    description = parse(localizedString('documentSelection.voiv2.beforeSelect.description'));
  }

  if (enableOneDocCondition) {
    const { index } = currentDocument;
    if (index < 1) {
      title = 'Select your I.D';
      description = 'Select the original I.D document you have available.';
    } else {
      title = 'Select an additional I.D';
      description = 'You will need to select an additional I.D for your identity check.';
    }
  }

  function getCurrentDocumentList() {
    const filterDocs = {};
    if (!isEmpty(VOI_FLOW_V2_ONLY_INCLUDE_IN_THIRD_LIST)) {
      filterDocs.appearOnlyAsThirdDoc = VOI_FLOW_V2_ONLY_INCLUDE_IN_THIRD_LIST;
    }
    if (!isEmpty(VOI_FLOW_V2_FILTER_DOCS)) {
      filterDocs.filters = VOI_FLOW_V2_FILTER_DOCS;
    }

    const { documentTypesToShow, isComplete } = getDocumentList({
      alreadySelectedDocs: selectedDocTypes,
      documentListPart1: docTypeNamesListPart1,
      documentListPart2: docTypeNamesListPart2,
      conditionsToBeConsideredAsComplete: requiredDocumentConfig,
      filterDocs
    });

    setWellDone(isComplete);

    return pipeFp(
      (docs) => intersectionWithFp((doc, type) => doc.type === type)(docs)(documentTypesToShow),
      sortByFp((doc) => selectedDocTypes.indexOf(doc.type)),
      mapFp((doc) => {
        return {
          ...doc,
          checked: selectedDocTypes.includes(doc.type)
        };
      }),
      orderByFp(['checked'], ['desc'])
    )(availableDocuments);
  }

  function onCardClick(item) {
    if (item.checked && !docTypeAlreadyUploaded.includes(item.type)) {
      // Disable untick for document already uploaded
      setSelectedDocTypes(selectedDocTypes.filter((docType) => docType !== item.type));
    } else if (!item.checked && !selectedDocTypes.includes(item.type)) {
      // sort the first selected doc from list one to first item
      const newSelectedDocTypes = [...selectedDocTypes, item.type];
      const docFromList1 = newSelectedDocTypes.filter((docType) =>
        docTypeNamesListPart1.includes(docType)
      );

      if (docFromList1.length > 0) {
        newSelectedDocTypes.sort((a, b) => {
          if (a === docFromList1[0]) {
            return -1;
          }
          return b === docFromList1[0] ? 1 : 0;
        });
      }
      setSelectedDocTypes(newSelectedDocTypes);
    }
  }

  function handleGoBack(e) {
    if (e) {
      e.preventDefault();
    }

    if (backurl && !isShownPrivacy('VOI_FLOW_V2')) {
      window.location.href = backurl;
    } else {
      onGoBack();
    }
  }

  function handleNextStep(e) {
    if (e) {
      e.preventDefault();
    }

    onNextStep(currentDocumentList);
  }

  return (
    <DocumentListScreen
      title={title}
      description={description}
      documentList={currentDocumentList}
      onClickDocumentItem={(item, index) => onCardClick(item, index)}
      footerButtons={footerButtons}
      hideDocImages={FLOW_V2_HIDE_IMAGES_IN_DOC_LIST}
      progressBarPercentage={progress}
    />
  );
}

DocumentSelection.propTypes = {
  initialSelectedDocumentList: PropTypes.array,
  requiredDocumentConfig: PropTypes.array,
  onGoBack: PropTypes.func,
  onNextStep: PropTypes.func,
  currentDocument: PropTypes.any,
  clearCardDetails: PropTypes.func,
  enableOneDocCondition: PropTypes.bool
};

export default connect(null, mapDispatchToProps)(DocumentSelection);

function mapDispatchToProps(dispatch) {
  return {
    clearCardDetails: () => dispatch(VoiFlowV2Action.clearCardDetails())
  };
}
