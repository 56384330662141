import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import camelCase from 'lodash/camelCase';
import { Validation } from 'calidation';
import { getValidations } from '@FLOW_V2_FLOW/components/Contents/VerifyDetails/verifyDetailsValidations';
import { defaultLettersAndSymbolsRegexMatch } from '@lib/validations/vefifyDetailsFormValidations';
import classNames from 'classnames';
import { Title } from '@lib/components/v2/Title';
import { camelCaseToLabel } from '@lib/Utils';
import { isDobUneditable } from '@lib/utils/v2/isDobUneditable';
import { isCardNumberFieldVisible } from '@lib/utils/cardNumberField';
import { getResidentalAddressLabel } from '@lib/addressfieldLabel';
import { getCountryLabelFromIso2Code } from '@lib/countryUtils';
import { DatePicker, Input } from '@lib/components/v2/Form';
import { AddressFinder } from '@lib/components/v2/AddressFinder';
import Loading from '@lib/components/v2/Datacheck/Datacheck.Loader';
import { localizedString } from '@languages';
import {
  FLOW_V2_DATEPICKER_FORMAT,
  FLOW_V2_DIGITAL_DL_HIDE_CARD_NUMBER_FIELD,
  FLOW_V2_FORCE_ADDRESS_VALIDATION_COUNTRY_ISO2,
  FLOW_V2_UNABLE_TO_EDIT_DOB_FOR_SECOND_DOCUMENTS
} from '@spotMobileConfig';

import classes from './Datacheck.style.module.scss';

const Datacheck = ({
  pageTitle = localizedString('verifyDetails.FLOW_V2_VERIFY_DETAILS_HEADING'),
  isLoading = false,
  data = {},
  onChange
}) => {
  if (isLoading) {
    return <Loading />;
  }

  const [inputs, setInputs] = useState({});
  useEffect(() => {
    setInputs(data);
  }, []);

  const handleChange = (field, value) => {
    const params = { ...inputs };
    params[field] = value;
    setInputs(params);
    onChange(field, value);
  };

  const idType = data.cardType || '';
  const datePickerProps = {
    displayedDateFormat: FLOW_V2_DATEPICKER_FORMAT
  };

  if (idType && idType.match(/MEDICARE/i)) {
    datePickerProps.displayedDateFormat = 'MM-YYYY';
  }

  const fields = [];
  const excluded = ['countryCode', 'cardType', 'documentId', 'state', 'gender'];

  if (
    !isCardNumberFieldVisible({
      idType,
      countryOfIssue: getCountryLabelFromIso2Code(data.countryCode),
      skipDigitalDriverLicence: FLOW_V2_DIGITAL_DL_HIDE_CARD_NUMBER_FIELD
    })
  ) {
    excluded.push('cardNumber');
  }

  const formValidations = getValidations({
    idType,
    countryOfIssue: getCountryLabelFromIso2Code(data.countryCode),
    values: data,
    flowType: 'FLOW_V2',
    cardType: data.cardType
  });

  const isDobDisabled = isDobUneditable({
    countryCodeIso2: data.countryCode,
    idType,
    restrictedSecondDocsList: FLOW_V2_UNABLE_TO_EDIT_DOB_FOR_SECOND_DOCUMENTS
  });

  Object.keys(data).forEach((field) => {
    if (!excluded.includes(field)) {
      if (field.match(/date/i)) {
        fields.push({
          id: field,
          label: camelCaseToLabel(field),
          value: data[field],
          date: true,
          disabled: field.match(/dateOfBirth/i) && isDobDisabled
        });
      } else if (field.match(/address/i)) {
        fields.push({
          id: field,
          label: getResidentalAddressLabel(),
          value: data[field],
          addressFinder: true
        });
      } else {
        fields.push({
          id: field,
          label: camelCaseToLabel(field),
          value: data[field],
          type: 'text'
        });
      }
    }
  });

  /**
   * Generate form fields.
   */
  const generateFields = fields.map((field, i) => {
    const { id, label, value, date, addressFinder, disabled } = field;
    const labelDataTestId = `datacheck-field-${i}-lbl`;
    const dataTestId = `datacheck-field-${i}`;

    const idCamelCased = camelCase(id);
    let validationRule;

    if (formValidations[idCamelCased]) {
      validationRule = { [id]: { ...formValidations[idCamelCased][idCamelCased] } };
    } else {
      validationRule = {
        [id]: {
          isRequired: `${label} ${localizedString('isRequired')}.`,
          isRegexMatch: defaultLettersAndSymbolsRegexMatch
        }
      };
    }

    // if its date
    if (date) {
      const restProps = {
        id,
        label,
        value,
        disabled
      };
      return (
        <div key={id} className={classNames('', classes.userDetailRow)}>
          <label data-testid={labelDataTestId} id={`label-${id}`} aria-hidden="true" htmlFor={id}>
            {field.label}
          </label>
          <Validation config={validationRule} initialValues={{ [id]: value }}>
            {({ dirty, errors: formErrors, setField }) => {
              return (
                <DatePicker
                  key={id}
                  {...datePickerProps}
                  className={classNames(classes.input)}
                  hasError={dirty[id] ? formErrors[id] : null}
                  onChange={(value) => {
                    handleChange(id, value);
                    setField({ [id]: value });
                  }}
                  {...restProps}
                  dataTestId={`${dataTestId}-datePicker`}
                />
              );
            }}
          </Validation>
        </div>
      );
    }
    if (addressFinder) {
      let countryCodeIso2ToVerify;
      if (value && value.manual) {
        countryCodeIso2ToVerify = value.selectedManualCountryCode
          ? value.selectedManualCountryCode
          : 'AU';
      } else {
        countryCodeIso2ToVerify = FLOW_V2_FORCE_ADDRESS_VALIDATION_COUNTRY_ISO2 || data.countryCode;
      }

      return (
        <div key={id} className={classNames('', classes.userDetailRow)}>
          <label data-testid={labelDataTestId} id={`label-${id}`} aria-hidden="true" htmlFor={id}>
            {field.label}
          </label>
          <AddressFinder
            initialAddressData={value}
            countryCodeIso2ToVerify={countryCodeIso2ToVerify}
            onChange={(value) => onChange(id, value)}
            dataTestId={`${dataTestId}-search`}
          />
        </div>
      );
    }
    return (
      <div key={id} className={classNames('', classes.userDetailRow)}>
        <label data-testid={labelDataTestId} id={`label-${id}`} aria-hidden="true" htmlFor={id}>
          {field.label}
        </label>
        <Validation config={validationRule} initialValues={{ [id]: value }}>
          {({ dirty, errors: formErrors, setField }) => {
            return (
              <Input
                key={id}
                placeholder={field.label}
                paddingLeft30
                className={classNames(classes.input)}
                hasError={dirty[id] ? formErrors[id] : null}
                onChange={(value) => {
                  handleChange(id, value);
                  setField({ [id]: value });
                }}
                {...field}
                dataTestId={`${dataTestId}-txt`}
              />
            );
          }}
        </Validation>
      </div>
    );
  });

  return (
    <div className={classes.wrapper}>
      <Title data-testid="datacheck-heading" title={pageTitle} />
      <div className={classes.container} aria-live="polite">
        {generateFields}
      </div>
    </div>
  );
};

Datacheck.propTypes = {
  data: PropTypes.object,
  pageTitle: PropTypes.string,
  isLoading: PropTypes.bool,
  onChange: PropTypes.func
};

Datacheck.defaultProps = {
  data: {}
};

export default Datacheck;
