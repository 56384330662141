import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Button } from '@lib/components/v2/Form';
import ReactSwipe from 'react-swipe';
import { localizedString } from '@languages';
import classes from './Tips.style.module.scss';

class Tips extends Component {
  static propTypes = {
    idType: PropTypes.string,
    onCapture: PropTypes.func.isRequired,
    lastButtonText: PropTypes.string,
    fullWidth: PropTypes.bool,
    tips: PropTypes.array,
    country: PropTypes.string,
    display: PropTypes.string
  };

  static defaultProps = {
    idType: 'licence',
    fullWidth: false,
    tips: [],
    country: 'NZ'
  };

  constructor(props) {
    super(props);

    this.state = {
      step: 0
    };

    this.isComponentMounted = false;
    this.reactSwipe = null;

    this.handleClick = this.handleClick.bind(this);
  }

  componentDidMount() {
    this.isComponentMounted = true;
  }

  componentWillUnmount() {
    this.isComponentMounted = false;
  }

  /**
   * Handle the next button
   * @return {Void}
   */
  handleClick() {
    const { step } = this.state;
    const { tips, onCapture } = this.props;

    if (step < tips.length - 1) {
      this.reactSwipe.next();
    } else {
      onCapture();
    }
  }

  // eslint-disable-next-line class-methods-use-this
  getTipFolder = (idType) => {
    let folder;
    if (
      idType.includes('DRIVERLICENCE') ||
      idType.includes('DLCARD') ||
      idType === 'PROOF_OF_AGE'
    ) {
      folder = 'licence';
    } else if (
      idType.includes('IDCARD') ||
      idType.includes('ID_CARD') ||
      idType.includes('IDENTITYCARD')
    ) {
      folder = 'idcard';
    } else {
      folder = 'passport';
    }

    return folder;
  };

  render() {
    const { step } = this.state;
    const { idType, tips, display, lastButtonText, fullWidth, country } = this.props;

    const title = tips[step];
    const buttonText = step === tips.length - 1 ? lastButtonText : localizedString('next');
    const swipeOptions = {
      continuous: false,
      callback: (step) => {
        if (this.isComponentMounted) {
          this.setState({ step });
        }
      }
    };

    let path;

    if (country === 'OTHER' && idType === 'PASSPORT') {
      path = `images/tips/${idType !== 'PASSPORT' ? 'licence' : 'passport'}/NZ`;
    } else if (idType === 'VISA') {
      path = `images/tips/visa`;
    } else {
      path = `images/tips/${this.getTipFolder(idType)}/${country}`;
    }

    const imgs = tips.map((tip, i) => (
      // eslint-disable-next-line react/no-array-index-key
      <img
        key={i}
        alt=""
        src={`${path}/tip-${i + 1}.jpg`}
        data-testid="cptrId-animation"
        aria-hidden="true"
      />
    ));
    const Dots = () => {
      return tips.map((tip, i) => (
        // eslint-disable-next-line react/no-array-index-key
        <li key={i} className={i === step ? classes.active : ''} data-testid={`cptrId-dot-${i}`} />
      ));
    };

    return (
      <div className={classNames(classes.wrapper, display)}>
        <div className="wp-b-tipsHeader">
          <h3
            style={{ width: fullWidth && '100%' }}
            className="b-title"
            data-testid="cptrId-instruction"
          >
            {title}
          </h3>
        </div>
        <ReactSwipe
          className={classes.image}
          swipeOptions={swipeOptions}
          ref={(ref) => {
            this.reactSwipe = ref;
          }}
        >
          {imgs}
        </ReactSwipe>
        <ul className={classes.dots}>
          <Dots />
        </ul>
        <Button
          onClick={this.handleClick}
          shadow
          large
          full
          label={buttonText}
          dataTestId="cptrId-next"
        />
      </div>
    );
  }
}

export default Tips;
