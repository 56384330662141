import React from 'react';
import PropTypes from 'prop-types';
import parse from 'html-react-parser';
import { Input } from '@lib/components/v2/Form';
import classNames from 'classnames';
import { isLocalizedStringDefined, localizedString } from '@languages';
import { useLogo } from '@js/hooks/useLogo';
import { DETAIL_MATCH } from '@spotMobileConfig';
import { getAltTextLogo } from '@lib/Utils';
import classes from './DetailMatch.style.module.scss';

const DetailMatch = ({ onChange, errors, dirty, submitted, ...restProps }) => {
  const finalLogo = useLogo();

  const fields = DETAIL_MATCH.map((key) => {
    const value = restProps[key];
    const localizedKey = `app.DETAIL_MATCH_LABEL.${key}`;
    return {
      id: key,
      label: isLocalizedStringDefined(localizedKey) ? localizedString(localizedKey) : '',
      value: value || ''
    };
  });

  const generateFields = fields.map((field) => {
    const { id, label } = field;

    return (
      <Input
        key={id}
        placeholder={label}
        className={classNames(classes.input)}
        hasError={dirty[id] || submitted ? errors[id] : null}
        onChange={(value) => onChange(id, value)}
        {...field}
      />
    );
  });

  return (
    <div className={classNames(classes.wrapper, 'b-container')}>
      <img alt={getAltTextLogo()} className="b-logo" src={finalLogo} />
      <br />
      <br />
      <div className={classes.title}>{parse(localizedString('app.DETAIL_MATCH_CONTENT'))}</div>
      <div className={classes.container}>{generateFields}</div>
    </div>
  );
};

DetailMatch.propTypes = {
  onChange: PropTypes.func,
  errors: PropTypes.object,
  dirty: PropTypes.object,
  name: PropTypes.string,
  reference: PropTypes.string,
  submitted: PropTypes.bool
};

DetailMatch.defaultProps = {
  name: '',
  reference: '',
  onChange: () => {}
};

export default DetailMatch;
